<template lang="html">
  <v-card
  class="evento-test msp-test-item"
  v-bind:class="{ghost:ghost, over: over}"
  @click="clickItem"
  >

      <v-list-item three-line
      >

  <v-list-item-avatar
    tile
    color="grey"
  >
  <i :class="'ev-all__icon msp-icon-test msp-icon-sport-'+test.sport_id" alt=""></i>

  </v-list-item-avatar>

         <v-list-item-content>

  <p class="ev-all__content--title hide-mobile">{{test.nome}}</p>
</v-list-item-content>  
</v-list-item>
</v-card>

</template>

<script>
export default {
  props: ['test'],

  computed: {
  },

  data: function () {
    return {
      over: false,
      ghost: false,
      calendario: 'test',
    }
  },
  watch: {

  },
  mounted: function() {
  },
  methods: {
    clickItem ()
    {
      this.$emit("click", this.test);
    },





  }

}
</script>

<style lang="scss">
.msp-test-item {
  .v-avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    min-height: var(--avatar-size);
    i {
      font-size: var(--avatar-size);
    }
  }
}

</style>
