<template lang="html">
  <div
    class="evento-risultato"
    :class="classRisultato"
    @click="clickItem"
    >
  <i
  class="risultato-icon" :class="'msp-icon-sport-'+risultato.sport_id" alt=""></i>

  <div class="msp-ui-panel hide-mobile">
    <div class="evento-risultato__header">

      <div class="ev-all__content--title evento-risultato__title">
        <p  v-text="risultato.nome"></p>
      </div>

      <div class="evento-risultato__risultato">
        <p class="test-risultato" v-html="formatRisultatoByUmId(risultato.risultato, risultato.risultato_um_id, risultato.risultato_um_descrizione)"></p>
        <div class="evento-risultato__fc">
          <p v-if="risultato.fc_media">FC media: {{risultato.fc_media}}</p>
          <p v-if="risultato.fc_max">FC max: {{risultato.fc_max}}</p>
        </div>
      </div>

      <p class="evento-risultato__note">{{risultato.note}}</p>
    </div>
  </div>



</div>

</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";

export default {

  mixins: [
    allenamentoCalcoli,
  ],

  props: ['risultato'],

  computed: {
    classRisultato: function ()
    {
      if (!this.risultato)
      {
        return "";
      }
      let a_ca = [];
      a_ca.push(
        'evento-risultato--sport-'+this.risultato.sport_id);

        if (this.risultato.abilitato)
        {
          a_ca.push('active');
        }

        return a_ca.join(" ");
      }
    },


    data: function () {
      return {
      }
    },
    watch: {

    },
    mounted: function() {
      //do something after mounting vue instance
    },
    methods: {
    clickItem () {
      this.$emit("click", this.risultato);
    },

      formatRisultatoByUmId: function (val, um_id, um_descrizione) {
        return this.MSP().formatRisultatoByUmId(val, um_id, um_descrizione);

      },

      openModal: function(/*risultato*/) { },


    }

  }
  </script>

  <style lang="scss">
  .evento-risultato {
    .risultato-icon {
      width: var(--avatar-size);
      height: var(--avatar-size);
      min-height: var(--avatar-size);
        font-size: var(--avatar-size);
      }

    position: relative;

    // mobile
    @media (max-width: 420px) {
      // @media (max-width: $size-medium) {
      // display: block;
      // flex-wrap: nowrap;
      margin: 0;
      display: flex;
      align-items: center;
    }

    opacity: 0.6;
    color: #fff;
    background-color: var(--background-evento);

    &.active{
      opacity: 1;
    }

    &--sport-1 {
      --background-evento: var(--col-sport-1);
    }
    &--sport-2 {
      --background-evento: var(--col-sport-2);
    }
    &--sport-3 {
      --background-evento: var(--col-sport-3);
    }
    .test-risultato {
      font-size: 1.3em;
      font-weight: bold;
    }
    &__note,
    &__fc {
      display: none;

    }
    [class*="msp-icon-sport"]{
      grid-column: 2
    }
    .msp-icon-test{
      background-color: transparent!important;
      grid-column: 1;
    }

    .msp-ui-panel {
      grid-column: 1/4;
    }
  }
  </style>
