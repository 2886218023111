<template >
  <div class="msp-gara-item">
    <v-card
    outlined
    color="var(--col-gara)"
    @click="clickItem"
    >

    <v-list-item three-line
    >

    <v-list-item-avatar
    tile
    color="white"
    >
    <i class="ev-all__icon " :class="'msp-icon-sport-'+gara.sport_id" alt=""></i>

  </v-list-item-avatar>

    <v-card-text>

      <div class="header">
        <img :src="'/img/coppa.svg#important-'+gara.importanza" class="img-gara" alt="">
    <p class="specialita">{{gara.sport_specialita_descrizione}}</p>
        <span class="luogo" v-if="gara.luogo">
          <i class="ev-all__icon--localita msp-icon-localita" aria-hidden="true"></i>
          <span class="ev-all__content--localita">{{gara.luogo}}</span>
        </span>


      </div>

      <p class="descrizione" >
      {{gara.descrizione}}
    </p>

      <div class="feedback">
          <p v-if="gara.risultato"><span class="ev-all__icon--time msp-icon-time" aria-hidden="true"></span>&nbsp;{{formatoRisultato(gara.risultato)}}</p>
  <msp-valutazione v-if="gara.valutazione" :readonly="true" :init-value="gara.valutazione"></msp-valutazione>
      </div>


</v-card-text>





</v-list-item>
<v-card-actions>

  <v-btn class="slot__note" text :class="classNote" small @click.prevent.stop="openChatCalendario(gara.calendario_id)">
      <v-icon class="icon-feedback note">{{iconaCommenti}}</v-icon><span class='count' v-if="countCommenti">{{countCommenti}}</span>
  </v-btn>
  
  
</v-card-actions>
</v-card>
</div>

</template>

<script>
import MSP from "@mixins/msp.js";
import mspValutazione from "./MspValutazione.vue";
export default {
  props: ['gara', 'day', 'week'],
  mixins: {
  },

  components: {
    mspValutazione,
},

  computed: {
      countCommentiNuovi: function () {
          return this.gara.chat_count_nuovi;
      },
      countCommenti: function () {
          return this.gara.chat_count_totale;
      },
      iconaCommenti: function () {
          if (this.countCommenti) {
              return "mdi-chat";
          }
          return "mdi-chat-outline";
      },
      classNote: function () {
          if (this.countCommentiNuovi) {
              return "nuovi";
          }

          return "";
      },
  },

  data: function () {
    return {
      over: false,
      ghost: false,
      hasMenu: false,

      type: 'gara'

    }
  },
  watch: {

  },
  mounted: function() {
    //do something after mounting vue instance
    this.mounted();
  },
  methods: {
    openChatCalendario: function (calendario_id) {
      this.$emit("open-chat-calendario", calendario_id);
    },

    formatoRisultato: function(val) {
        return MSP.sec2hms(val);
  },

    clickItem: function(id) {
      this.$emit('click', id);
    },

    mounted: function() {
      this.$emit('mounted', this.gara);
    },

  }

}
</script>

<style lang="scss" >
.msp-gara-item {
    .descrizione {
        font-weight: bold;
        font-size: 1.5em;
        line-height: 1;
    }

  .header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    gap: 0px 10px;
  }
  p {
    margin: 0;
  }
  .img-gara {
    grid-column: 1;
    grid-row: 1 / -1;
    width: var(--avatar-size);
  }
  .specialita,
  .luogo {
      padding: 0;
      margin: 0;
      grid-column: 2;
  }
  .v-avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    min-height: var(--avatar-size);
    i {
      font-size: var(--avatar-size);
    }
  }
    h2 {
    font-size: 1em;
  }
  [class^="ev-all__icon"] {
    font-size: 1em;
  }
    .msp-valutazione-mobile {
        .valutazione-testo {
            display: none;
        }
        .valutazione-icona {
        font-size: 1.2em;
        }
    }
    .feedback {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}
</style>
