export const fab = {
    atleta: [
        {
            "color": "var(--col-gara)",
            "click": ['openFabModal', 'gara'],
            "label": "Gara",
            "icon": "msp-icon-gara",
        },
        {
            "color":"var(--col-grigio-scuro)",
            "click":['openFabModal','impegno' ] ,
            "label": "Impegno",
            "icon": "msp-icon-impegni",
        },
        {
            "color":"var(--col-grigio-scuro)",
            "click":['addTestFromFab'],
            "label": "Test",
            "icon": "msp-icon-test",
        },
        {
            "color":"var(--col-sport-11)",
            "click":['openFabModal','nota'],
            "label": "Nota",
            "icon": "msp-icon-custom",
        },
        {
            "color":"var(--col-grigio-scuro)",
            "click":['addMisurazioneFromFab'],
            "label": "Misurazioni",
            "icon": "msp-icon-bilancia",
        },
    ],
    allenatore: [
        {
            "color":"var(--col-sport-11)",
            "click":['openFabModal','nota'],
            "label": "Nota",
            "icon": "msp-icon-custom",
        },
    ],
};
