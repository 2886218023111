<template >
    <div class="msp-grafico-andature-semplice">
        <div v-for="(item, i) in items" class="andatura"
            :key="i" :style="getStyle(item)">
            <div class="andatura-bar"></div>
        </div>
    </div>
</template>

<script>
import ZONE from "@cached/zone.json"
const component = {
  props: ['items', 'maxX', 'maxY', 'colore'],
  mixins: [
  ],
  data: function () {
    return {
        zone: ZONE,
    }
  },
  computed: {




  },
  methods: {


    getStyle: function (item) {
      let a_style=[];
      const width = item[0]/this.maxX*100;
      const height = item[1]/this.maxY;
      const zona = ZONE.find(el=>el.id === item[3]);
      a_style.push(`--width:${width}`);
      a_style.push(`--height:${height}`);
      if (this.colore) {
        const color = zona.colore;
        a_style.push(`--color:${color}`);
      }
      return a_style.join(";");
    },

  }
}
export default component;
</script>

<style lang="scss">
.msp-grafico-andature-semplice {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    padding: 3px 3px 0 3px;
    .dati-item {
        display: flex;
        flex: 1;
        white-space: nowrap;
        padding: 0 10px;
        align-items: center;
        background: var(--col-grigio-chiarissimo);
        gap: 3px;
        border-radius: 5px;
    }
    .dati-value {
        font-weight: normal;

    }
    .andatura {
        --height: 1;
        --width: 10;
        --scale-y: 100%;
        --scale-x: 1%;
        --color: #ccc;
        --color-hover: rgba(255,255,255,0.2);
        position: relative;
        width: calc(var(--scale-x) * var(--width));
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding:0px;
        &:hover {
            background: var(--color-hover);
        }
    }
    .andatura-bar {
        position: relative;
        background: var(--color);
        height: calc(var(--scale-y) * var(--height));
        width: 100%;
        position: relative;
    }

    .andatura-tooltip {
        position: absolute;
        display: none;
    }
    .andatura:hover .andatura-tooltip {
        display: block;
        left: 0;
        bottom: 100%;
        z-index: 100;
        padding: 10px;
        text-align: left;
        background: var(--col-grigio-scuro);
        color: #fff;
        border-radius: 5px;
        margin-bottom: 0px;
        white-space: nowrap;
        & .codice-zona {
            display: block;
            border-bottom: 1px solid #fff;
            margin-bottom: 5px;
        }
    }

    .dettaglio,
    .serie,
    .ripetuta,
    .andatura {
        display: flex;
        align-items: flex-end;
    }

}

</style>
