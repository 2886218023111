<template>
  <div class="msp-misurazione-item" >
      <msp-misurazione-valore
v-for="(valore,key) in misurazione.valori"
:key="key"
:column="key"
:parametro="parametro"
:misurazione="misurazione"
:valore="valore"
/>
        </div>

</template>

<script>
import MspMisurazioneValore from "./MspMisurazioneValore.vue";

export default {
    components: {
        MspMisurazioneValore,
    },
  mixins: [
  ],

  props: [
      'misurazione',
      'parametri'
  ],

  computed: {
      parametro: function () {
          if (!this.hasParametri) return null;
          if (!this.misurazione) return null;
          return this.parametri.find(el=>el.codice == this.misurazione.parametro);
      },
      hasParametri: function () {
          return this.parametri && this.parametri.length;
      },
  },

  data: function () {
    return {
      active: false
    }
  },
  watch: {

  },
  mounted: function() {

  },
  methods: {
  }

}
</script>

<style lang="scss">
.msp-misurazione-item {
}

</style>
